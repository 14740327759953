import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { Link } from "gatsby";
import pic from "../assets/images/dsc.png";
import { logAnalytics } from "../firebase";
import { ACTIVE_PAGE } from "../components/nav/utils";

export default function DesignSoftwareCompatPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.DESIGNSOFTWARECOMPATIBILITY}>
      <Seo title={""} />

      <header className="site__header">
        {/* <h1 className="header__motto header__motto--no-margin">
          Design Software Compatibility
        </h1> */}
      </header>

      <main className="content">
        <img src={pic} className="img-lg" alt="" />
        <Link
          className="btn btn--center"
          to="/rfp"
          onClick={() => logAnalytics("clicked_receive_quote")}
        >
          Digitize Your Project
        </Link>
      </main>
    </SiteWrapper>
  );
}
